"use strict";

////////////////////
// Module Imports //
//////////////////// 

import { getOuterHeight } from "@wearegood/good-utilities";

////////////////////// 
// Module Constants //
//////////////////////

const SEL_PAGE_BANNER = "#pageBanner";
const SEL_PAGE_HEADER = ".cp_Brand";

////////////////////////////////
// Module Classes & Functions //
////////////////////////////////

export default class BannerAction {
  constructor(elem) {
    this.bannerAction = elem;
    this.bindCustomMessageEvents();
  }

  scrollPastBanner () {
    const BANNER_COMP = document.querySelector(SEL_PAGE_BANNER);

    const BANNER_HEIGHT = getOuterHeight(BANNER_COMP);
    const PAGE_HEADER = document.querySelector(SEL_PAGE_HEADER);
    let pageHeaderHeight = parseFloat(getComputedStyle(PAGE_HEADER, null).height.replace("px", ""));

    if(pageHeaderHeight > 65) {
      pageHeaderHeight = 65;
    }

    const SCROLL_POS_AFTER_BANNER = BANNER_HEIGHT - pageHeaderHeight;

    window.scrollTo({
      top: SCROLL_POS_AFTER_BANNER,
      left: 0,
      behavior: 'smooth'
    });
  }

  handleClickEvent (e) {
    e.preventDefault();
    this.scrollPastBanner();
  }

  /**
   * Add event handler for main navigation toggle
   *
   * @memberof InPageMenu
   */
  bindCustomMessageEvents() {
    this.bannerAction.addEventListener("bannerAction", this.handleClickEvent.bind(this));
  }
}