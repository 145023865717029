"use strict";

/////////////
// imports //
/////////////

//import smoothscroll from 'smoothscroll-polyfill';
import "nodelist-foreach-polyfill";
import { createDelegatedEventListener, messages as MESSAGES } from "@wearegood/good-utilities";
import TabbedContent from "Modules/Tabs/Tabs";

///////////////
// Constants //
///////////////

const SEL_TAB_COMPONENT = "[data-tabs=component]";
const SEL_TAB_CONTROL_GLOBAL = "[data-tabs=component] [data-tabs-link]";

const SEL_TAB_CONTROL_SECTION_GLOBAL = "[data-tabs=component] [data-tabs-link-section]";
const SEL_TAB_HIDDEN_TOGGLE = "[data-tabs-hidden=toggle]";

////////////////////////////////
// Module Classes & Functions //
////////////////////////////////

/**
 * delegateEvents - Create delegated event listeners for the components within this module
 *
 * @returns {type} Description
 */
function delegateEvents() {
  createDelegatedEventListener("click", SEL_TAB_CONTROL_GLOBAL, "selectTab");
  createDelegatedEventListener("click", SEL_TAB_CONTROL_SECTION_GLOBAL, "selectTabSection");
  createDelegatedEventListener("click", SEL_TAB_HIDDEN_TOGGLE, "showHiddenTabs");
}

/**
 * Initialise the Tabbed Content components on the page.
 *
 * @returns {type} Description
 */
export default function initialiseTabs() {
  // Create delegated event listeners for the components within this module
  delegateEvents();

  // Find and initialise Tab components using the Tab class
  const TAB_COMPONENTS = document.querySelectorAll(SEL_TAB_COMPONENT);

  TAB_COMPONENTS.forEach((element) => {
    const newTabbedContent = new TabbedContent(element);
  });
}