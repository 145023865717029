// PageMode module
"use strict";

////////////////////
// Module Imports //
////////////////////


import "nodelist-foreach-polyfill";
import "intersection-observer";

////////////////////////////////////
// Module Constants and Variables //
////////////////////////////////////

// Image Observer
const headerObserverOptions = {
  root: null,
  rootMargin: "0px",
  threshold: 0.95
}; 

// const pageSectionObserverOptions = {
//   root: null,
//   rootMargin: "0px",
//   threshold: [0,1]
// }; 

// const viewAnimationObserverOptions = {
//   root: null,
//   rootMargin: "0px",
//   threshold: 1
// }; 

//let pageSectionObserver;
let headerObserver;
//let viewAnimationObserver;

//let previousRatio = 0;
//let pageScrollPosition = 0;

////////////////////////////////
// Module Classes & Functions //
////////////////////////////////


/**
 * Update the module pageScrollPosition variable to the current page scrollTop value
 *
 */
function updateScrollPosition() {
  pageScrollPosition = getBodyScrollTop();
}


/**
 * Get the current scroll position of the top of the viewport
 *
 * @returns
 */
function getBodyScrollTop() { 
  const bodyElement = document.scrollingElement || document.documentElement;
  return bodyElement.scrollTop;
}

/**
 *
 *
 * @param {*} steps
 * @returns
 */
// function buildThresholdList(steps) {
//   var thresholds = []; 
//   var numSteps = steps;

//   for (var i = 1.0; i <= numSteps; i++) {
//     var ratio = i / numSteps;
//     thresholds.push(ratio);
//   }

//   thresholds.push(0);
//   return thresholds;
// }

/**
 * Instantiate an IntersectionObserver object for the smart image components
 *
 */
function initialiseHeaderObserver() {
  headerObserver = new IntersectionObserver(handleHeaderIntersection, headerObserverOptions);
  const bannerElement = document.getElementById('pageTop');

  window.console.log(bannerElement);

  if(bannerElement) {
    headerObserver.observe(bannerElement);
  }  
}

function handleHeaderIntersection(entries, observer) {
  entries.forEach((entry) => {
    if (entry.intersectionRatio < headerObserverOptions.threshold) {
      document.body.classList.remove("is_AtTop");
    } else {
      document.body.classList.add('is_AtTop');
    }
  });
}

/**
 * Instantiate an IntersectionObserver object for page section modes
 *
 */
// function initialisePageSectionObserver() {
//   pageSectionObserver = new IntersectionObserver(handlePageSectionIntersection, pageSectionObserverOptions);

//   const pageSections = document.querySelectorAll('[data-section]');
//   pageSections.forEach(e => {
//     pageSectionObserver.observe(e);
//   });
// }

// function handlePageSectionIntersection(entries, observer) {
//   entries.forEach((entry) => {
//     const currentRatio = entry.intersectionRatio;
//     const isIntersecting = entry.isIntersecting;
 
//     // If Scrolling Down
//     if (getBodyScrollTop() > pageScrollPosition && isIntersecting) {
//       if (currentRatio > previousRatio && isIntersecting) {
//         if (entry.intersectionRatio >= 0.8) {
//           document.body.dataset.mode = entry.target.dataset.section;
//         }
//       }
//     } 
//     // If Scrolling Up
//     else if (getBodyScrollTop() < pageScrollPosition && isIntersecting) {
//       if (currentRatio > previousRatio) {
//         if (entry.intersectionRatio <= 0.2) {
//           document.body.dataset.mode = entry.target.dataset.section;
//         }
//       } 
//     }

//     updateScrollPosition();
//   });
// }


/**
 * Instantiate an IntersectionObserver object components that animate once in view
 *
 */
// function initialiseViewAnimationObserver() {
//   viewAnimationObserver = new IntersectionObserver(handleViewAnimationIntersection, viewAnimationObserverOptions);

//   const components = document.querySelectorAll('[data-animate]');
//   components.forEach(e => {
//     viewAnimationObserver.observe(e);
//   });
// }

// function handleViewAnimationIntersection(entries, observer) {
//   entries.forEach((entry) => {
//     if (entry.intersectionRatio >= viewAnimationObserverOptions.threshold) {
//       entry.target.classList.add('is_InView');
//       observer.unobserve(entry.target);
//     }
//   });
// }


/**
 * initModule - Initialise this module and the components contained in it
 *
 * @returns {type} Description
 */
export function initModule() {
  initialiseHeaderObserver();
  //initialisePageSectionObserver();
  //initialiseViewAnimationObserver(); 
}

export default { initModule: initModule };