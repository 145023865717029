"use strict";

////////////////////
// Module Imports //
////////////////////

import PubSub from "pubsub-js";
import "nodelist-foreach-polyfill";
import { createDelegatedEventListener, createCustomEvent } from "@wearegood/good-utilities";

//////////////////////
// Module Constants //
//////////////////////

const SEL_PAGE_SECTION = '.cp_Title[id]';
const SEL_INPAGE_MENU_LINK = "[data-inpage-menu=link]";
const SEL_PAGE_HEADER = ".cp_Brand";

////////////////////////////////
// Module Classes & Functions //
////////////////////////////////

/**
 *
 *
 * @export
 * @class InPageNavigation
 */
export default class InPageNavigation {
  constructor(elem) {
    this.component = elem;
    this.menu = this.component;
    this.topLinkText = this.component.dataset.toplink || '';
    this.type = this.component.dataset.inpageNavType || 'menu';

    this.buildMenuLinks();

    this.bindCustomMessageEvents();
    this.subscribeToEvents();
  }

  /**
   *
   *
   * @memberof InPageMenu
   */
  buildMenuLinks () {
    let secondaryMenu = null;
    let lastTopLevelListItem = null;

    // Get page sections with IDs
    const PAGE_SECTIONS = document.querySelectorAll(SEL_PAGE_SECTION);

    if(PAGE_SECTIONS.length >0) {
      createDelegatedEventListener("click", SEL_INPAGE_MENU_LINK, "toSection");
    }

    // Loop through the page sections...
    PAGE_SECTIONS.forEach(element => {
      const SECTION_ID = element.getAttribute('id');
      // Get HTML from component content, including prefix in span
      const MENU_ITEM_TITLE = element.textContent;
      // If we have the section ID (for the link href) and the item title (for the link text),
      // build the link and add to the menu
      if(SECTION_ID !== null && MENU_ITEM_TITLE !== null) {

        // if(MENU_ITEM_TITLE.tagName === 'H2') {

          secondaryMenu === null;
          this.menu.insertAdjacentHTML("beforeend", this.buildSingleMenuLink(MENU_ITEM_TITLE, SECTION_ID));
          lastTopLevelListItem = this.component.querySelector('li:last-child');

        // } else if (MENU_ITEM_TITLE.tagName === 'H3') {

        //   // Get the last item in the nav and check if there's a UL child in it
        //   window.console.log(secondaryMenu);
        //   if(!lastTopLevelListItem.querySelector('ul.cp_InPageMenu__subMenu')) {
        //     secondaryMenu = `<ul class="cp_InPageMenu__subMenu"></ul>`;
        //     lastTopLevelListItem.insertAdjacentHTML("beforeend",secondaryMenu);
        //   }

        //   lastTopLevelListItem.querySelector('ul.cp_InPageMenu__subMenu').insertAdjacentHTML("beforeend", this.buildSingleMenuLink(MENU_ITEM_TITLE, SECTION_ID));
        // }

        // Update class on prefix is this exists
        // MENU_ITEM_TITLE.querySelector('span').classList.remove('cp_SectionTitle__prefix');
        // MENU_ITEM_TITLE.querySelector('span').classList.add('cp_InPageMenu__prefix');
        // // Build menu item to add to menu container
        // const NEW_MENU_LINK = `<li class="cp_InPageMenu__menuItem"><a class="cp_InPageMenu__menuLink" href="#${SECTION_ID}" data-inpage-menu="link">${MENU_ITEM_TITLE.innerHTML}</a></li>`;
        // this.menu.insertAdjacentHTML("beforeend", NEW_MENU_LINK);

        // Add back to top
        // if(this.component.dataset.toplink.length > 0) {
        //   const TO_TOP_LINK = `<a class="cp_SectionTitle__topLink ct_Pseudo" href="#" data-inpage-link="top">${this.component.dataset.toplink}</a>`;
        //   SECTION_TITLE.insertAdjacentHTML("beforeend",TO_TOP_LINK);
        // }
      }
    });
  }

  buildSingleMenuLink (ITEM_TITLE, SECTION_ID) {
    // Update class on prefix is this exists
    //ITEM_TITLE.querySelector('span').classList.remove('cp_SectionTitle__prefix');
    //ITEM_TITLE.querySelector('span').classList.add('cp_InPageMenu__prefix');
    // Build menu item to add to menu container
    let newMenuLink;

    if(this.type === 'submenu') {
      newMenuLink = `<li class="cp_MainNav__item--inpage cp_MainNav__item"><a class="cp_MainNav__link--inpage cp_MainNav__link" href="#${SECTION_ID}" data-inpage-menu="link">${ITEM_TITLE}</a></li>`;
    } else {
      newMenuLink = `<li class="cp_InPageNav__menuItem"><a class="cp_InPageNav__menuLink ob_Link" href="#${SECTION_ID}" data-inpage-menu="link">${ITEM_TITLE}</a></li>`;
    }

    return newMenuLink;
  }

  /**
   *
   *
   * @memberof InPageMenu
   */
  scrollToSection (section) {
    PubSub.publish('mainnav/close');

    let topPos = 0;
    if(section === 'top') {
      topPos = 0;
    } else {
      topPos = document.querySelector(section).getBoundingClientRect().top;

      const VIEWPORT_SCROLL_POSITION = (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0);
      const PAGE_HEADER = document.querySelector(SEL_PAGE_HEADER);
      let pageHeaderHeight = parseFloat(getComputedStyle(PAGE_HEADER, null).height.replace("px", ""));

      if(pageHeaderHeight > 65) {
        pageHeaderHeight = 65;
      }

      topPos = topPos + VIEWPORT_SCROLL_POSITION - pageHeaderHeight;
    }

    // Use the native JS scrolling functionality to smooth scroll to the correct position
    window.scrollTo({
      top: topPos,
      left: 0,
      behavior: 'smooth'
    });
  }

  /**
   * Manage the custom "toSection" event
   *
   * @param {*} event
   * @memberof InPageNavigation
   */
  manageToSectionEvent (event) {
    event.preventDefault();
    this.scrollToSection(event.target.getAttribute('href'));
  }

  /**
   * Manage the custom "toTop" event
   *
   * @param {*} event
   * @memberof InPageNavigation
   */
  manageToTopEvent (event) {
    event.preventDefault();
    this.scrollToSection('top');
  }

  /**
   * Add event handler for main navigation toggle
   *
   * @memberof InPageMenu
   */
  bindCustomMessageEvents() {
    this.component.addEventListener("toTop", this.manageToTopEvent.bind(this));
    this.component.addEventListener("toSection", this.manageToSectionEvent.bind(this));
  }

  /**
   * Subscribe to global messages
   *
   * @memberof InPageNavigation
   */
  subscribeToEvents () {
    PubSub.subscribe("backToTop", () => {
      this.component.dispatchEvent(createCustomEvent("toTop"));
    });
  }
}
