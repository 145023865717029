// ShowHide Component Manager module
"use strict";

/////////////
// Imports //
/////////////

import "nodelist-foreach-polyfill";
import { createDelegatedEventListener } from "@wearegood/good-utilities";
import * as CONSTANTS from "Modules/ShowHide/constants";
import ShowHide from "Modules/ShowHide/ShowHide";
import ShowHideGroup from "Modules/ShowHide/ShowHideGroup";

///////////////
// Constants //
///////////////

/////////////////////////
// Classes & Functions //
/////////////////////////

function initialiseShowHiders() {
  const COMPONENTS = document.querySelectorAll(CONSTANTS.SEL_COMPONENT); 

  COMPONENTS.forEach(element => {
    // Check showhide component isn't in a group.
    // If it is, the ShowHideGroup object will handle creating it.

    const SHOWHIDE_CONFIG = JSON.parse(element.getAttribute("data-showhide-config")) || {};

    if(SHOWHIDE_CONFIG.group == undefined || SHOWHIDE_CONFIG.group == false) {
      let basicShowHider = new ShowHide(element);
    }
  });
} 

function initialiseShowHideGroups() {
  const COMPONENTS = document.querySelectorAll(CONSTANTS.SEL_GROUP_COMPONENT); 

  COMPONENTS.forEach(element => {
    let basicShowHideGroup = new ShowHideGroup(element);
  });
} 

/**
 * Delegate global event listeners for carousel components
 *
 */
function delegateEvents() {
  createDelegatedEventListener("click", CONSTANTS.SEL_ACTION, CONSTANTS.ACTION_EVENT);
}

/**
 * initModule - Initialise this module and the components contained in it
 *
 * @returns {type} Description
 */
export default function initShowHideModule() {
  // Find and initialise Show/Hide components using the ShowHide class
  delegateEvents();
  initialiseShowHiders();
  initialiseShowHideGroups();
}
