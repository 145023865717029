"use strict";

////////////////////
// Module Imports //
//////////////////// 

import { MAP_DATA, MAP_THEME } from "Modules/Maps/map_data"; 
import { createCustomEvent, searchArrayByItemPropertyValue } from "@wearegood/good-utilities";
import MarkerClusterer from '@googlemaps/markerclustererplus';
import PubSub from "pubsub-js";

////////////////////// 
// Module Constants //
//////////////////////

const SEL_AREA_MAP = "[data-area-map=component]";
const SEL_AREA_MAP_CONTAINER = "[data-area-map=map]";
const SEL_AREA_MAP_INDEX = "[data-area-map=index]";

const MARKER_IMAGE_PATH = "/assets/img/ui/map_marker_";
const MARKER_CLUSTER_PATH = "/assets/img/ui/map_cluster_";
const LUCENT_MARKER_IMAGE_PATH = "/assets/img/ui/lucent_logo_black.svg";
const MARKER_IMAGE_PATH_SUFFIX = ".svg";

////////////////////////////////
// Module Classes & Functions //
////////////////////////////////

class AreaMap {
  constructor(elem) {
    this.mapContainer = elem.querySelector(SEL_AREA_MAP_CONTAINER);
    this.mapIndex = elem.querySelector(SEL_AREA_MAP_INDEX);
    this.mapID = this.mapContainer.dataset.areaMapId;
    this.googleMap;
    this.bounds = new google.maps.LatLngBounds();
    this.mapData = searchArrayByItemPropertyValue(this.mapID, "map", MAP_DATA);
    this.markers = [];
    this.markerClusterer = null;
    this.lucentPolygonCoords = [
      { lat: 51.510690255203926, lng: -0.13542726036619668 },
      { lat: 51.51076860984063, lng: -0.13407645239492075 },
      { lat: 51.510253357979835, lng: -0.13443363355150853 },
      { lat: 51.51022755051011, lng: -0.13458992894576333 },
      { lat: 51.51038729654833, lng: -0.13517901918073366 },
      { lat: 51.51066125105866, lng: -0.13544854899327508 },
    ];

    this.subscribeToEvents();
    this.bindCustomMessageEvents();
    this.initAreaMap();
    this.createPolygon(this.lucentPolygonCoords, this.googleMap);
  }

  // Create Map
  initAreaMap() {
    var mapCenter = this.mapData.centre;
    
    this.googleMap = new google.maps.Map(this.mapContainer, {
      center: mapCenter,
      zoom: 14,
      styles: MAP_THEME,
      disableDefaultUI: true
    });

    this.mapData.categories.forEach((category) => {
      
      this.googleMap.fitBounds(this.bounds);
      this.createCategoryIndex(category.name,category.locations,category.theme);
    });

    // Set first set of markers
    this.createMarkers(this.mapData.categories[0].locations, this.mapData.categories[0].theme);

    // this.markerClusterer = new MarkerClusterer(this.googleMap, this.markers, {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
    //this.createMarkers(this.mapData.categories[0].locations, this.mapData.categories[0].theme);
  }

  // Create polygon for map
  createPolygon(lucentPolygonCoords, map) {
    const lucentPolygon = new google.maps.Polygon({
      paths: lucentPolygonCoords,
      strokeColor: "#1E193F",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#1E193F",
      fillOpacity: 0.35,
    });
  
    lucentPolygon.setMap(map);
  }

  // Create Markers for map
  createMarkers(arrayOfMarkers, theme) {
    this.clearMap(this.googleMap);
    this.bounds = new google.maps.LatLngBounds();

    // Create Lucent Marker
    let lucentMarkerImage = {
      url: LUCENT_MARKER_IMAGE_PATH,
      scaledSize: new google.maps.Size(83, 21)
    }

    let lucentMarker = new google.maps.Marker({
      position: this.mapData.centre,
      map: this.googleMap,
      optimized: false,
      icon: lucentMarkerImage
    });

    this.bounds.extend(lucentMarker.getPosition());
    this.markers.push(lucentMarker);

    // Create category markers
    arrayOfMarkers.forEach((location, i) => {
      const MARKER_NUMBER = (i + 1).toString();
      const MARKER_COORDS = {lat: parseFloat(location.lat), lng: parseFloat(location.long)};

        let markerImage = {
          url: MARKER_IMAGE_PATH + theme + MARKER_IMAGE_PATH_SUFFIX,
          scaledSize: new google.maps.Size(35, 44)
        }

        let marker = new google.maps.Marker({
          position: MARKER_COORDS,
          //animation: google.maps.Animation.DROP,
          map: this.googleMap,
          optimized: false,
          icon: markerImage,
          label: {text: MARKER_NUMBER, color: "white",fontSize: '18px'}
        });

        this.bounds.extend(marker.getPosition());
        this.markers.push(marker);
    });
    
    this.googleMap.fitBounds(this.bounds);

    const CLUSTER_IMAGE_PATH = MARKER_CLUSTER_PATH + theme + MARKER_IMAGE_PATH_SUFFIX;

    let mcOptions = {
        styles: [{
            height: 37,
            url: CLUSTER_IMAGE_PATH,
            //url: "storeCluster.png",
            textSize: 0,
            textColor: 515557,
            width: 39,
            anchorText: [-15,12]
        }],
        maxZoom:20,
        gridSize:40
    };

    this.markerClusterer = new MarkerClusterer(this.googleMap, this.markers, mcOptions);
  }

  createCategoryIndex(catName, locations, theme) {
    if(locations.length && this.mapIndex) {
      let indexItems = "";

      locations.forEach((item, i) => {
        let indexItemTemplate = `<li class="cp_Map__indexListItem">${item.location}</li>`;
  
        indexItems = indexItems + indexItemTemplate;
      });
      
      
      let indexComp = `<section class="cp_Map__indexGroup" data-showhide="component" data-showhide-config='{"group": true, "name":"${catName}"}'>
      <h3 class="cp_Map__indexTitle--theme-${theme} cp_Map__indexTitle"><a class="cp_Map__indexToggle" href="#" data-showhide="toggle">${catName}<span class="cp_Map__indexToggleIcon"><svg xmlns="http://www.w3.org/2000/svg" width="19.593" height="10.858" viewBox="0 0 19.593 10.858"><g transform="translate(-301.998 -756.856)"><path d="M0,0-9.266-9.266-18.531,0" transform="translate(302.528 757.387) rotate(180)" fill="none" stroke="#fff" stroke-width="1.5"/><path d="M0,0H19.591V-10.856H0Z" transform="translate(321.591 756.856) rotate(180)" fill="none"/></g></svg></span></a></h3>
      <ol class="cp_Map__indexList" data-showhide="content">${indexItems}</ol>
      </section>`;
  
      this.mapIndex.insertAdjacentHTML("beforeend", indexComp);
    }    
  }

  clearMap() {
    if(this.markerClusterer !== null) {
      this.markerClusterer.clearMarkers();
      this.markers = [];
    } else {
      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      this.markers = [];
    }
  }

  manageMapDisplayedEvent(e) {
    e.preventDefault();
    this.googleMap.fitBounds(this.bounds);
  }

  /**
   * 
   *
   * @memberof Modal
   */
  bindCustomMessageEvents () {
    this.mapContainer.addEventListener(
      "map/refresh",
      this.manageMapDisplayedEvent.bind(this)
    );
  }

  /**
   * Subscribe object to Global Messages
   * @function
   */
  subscribeToEvents () {
    PubSub.subscribe('map-displayed', () => {
      this.mapContainer.dispatchEvent(createCustomEvent("map/refresh"));
    });

    PubSub.subscribe('groupOpen', (topic, data) => {  
      let categoryToOpen = data;      

      this.mapData.categories.forEach(category => {
        if(category.name === categoryToOpen) {
          this.createMarkers(category.locations, category.theme);
        }
      });
    });
  }
}

/**
 * delegateEvents - Create delegated event listeners for the components within this module
 *
 * @returns {type} Description
 */
function delegateEvents() {
  // createDelegatedEventListener("click", SEL_TAB_CONTROL_GLOBAL, "selectTab");
}

/**
 * Initialise the Tabbed Content components on the page.
 *
 * @returns {type} Description
 */
export default function initialiseMaps() {
  // Create delegated event listeners for the components within this module
  delegateEvents();

  // Find and initialise Area Map components using the AreaMap
  const AREA_MAP_COMPONENTS = document.querySelectorAll(SEL_AREA_MAP);

  AREA_MAP_COMPONENTS.forEach((element) => {
    const newAreaMap = new AreaMap(element);
  });
}


