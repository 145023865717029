// Map Data for area maps

export const MAP_DATA = [
  {
    "map":"culture",
    "centre": {lat: 51.510538170250584, lng: -0.13475638367586099},
    "categories": [
      {
        "name":'Culture',
        "theme": 'green',
        "locations": [
          {
            "location":"Royal Academy of Arts",
            "lat":"51.50944269120449",
            "long":"-0.1398628450715242", 
          },
          {
            "location":"Ronnie Scott's",
            "lat":"51.513477256418795",
            "long":"-0.13152427211783474"
          },
          {
            "location":"Institute of Contemporary Arts",
            "lat":"51.506173208953065",
            "long":"-0.13082202608571034"
          }, 
          {
            "location":"London Coliseum",
            "lat":"51.50988988590159",
            "long":"-0.12651870641641924"
          },
          {
            "location":"BAFTA Piccadilly",           
            "lat":"51.50859685346435",
            "long":"-0.1373696896763712", 
          }
        ]
      },
      {
        "name":'Landmarks',
        "theme": 'orange',
        "locations": [
          {
            "location":"Trafalgar Square",
            "lat":"51.50803319241169",
            "long":"-0.1280703351893302", 
          },
          {
            "location":"Leicester Square",
            "lat":"51.51035411321724",
            "long":"-0.13005939079036904",
          },
          {
            "location":"Chinatown",
            "lat":"51.51099473798157",
            "long":"-0.13181707374840698", 
          },
          {
            "location":"Burlington Arcade",
            "lat":"51.50899926835394",
            "long":"-0.14018594357615927", 
          },
          {
            "location":"Carnaby Street",
            "lat":"51.51320011040882",
            "long":"-0.13884987220331368", 
          },
        ]
      },
      {
        "name":'Food & Drink',
        "theme": 'red',
        "locations": [
          {
            "location":"Whole Foods",
            "lat":"51.510571408931646",
            "long":"-0.1361210435653767", 
          },
          {
            "location":"Kricket",
            "lat":"51.51082130362852",
            "long":"-0.13515374691194196", 
          },
          {
            "location":"Dean Street Town House",
            "lat":"51.51349520275716",
            "long":"-0.13250317035414552", 
          },
          {
            "location":"Bocca di Lupo",
            "lat":"51.51162423958387",
            "long":"-0.13395374336775231", 
          },
          {
            "location":"J Sheekey",
            "lat":"51.51089877963551",
            "long":"-0.1277753433677783", 
          },
          {
            "location":"Bob Bob Ricard",
            "lat":"51.512314510135084",
            "long":"-0.13728206555266148", 
          },
          {
            "location":"Kingly Court",
            "lat":"51.51251681792162",
            "long":"-0.13871271021828713", 
          },
          {
            "location":"Ham Yard Hotel",
            "lat":"51.51125309000377",
            "long":"-0.1348021411176885", 
          },
          {
            "location":"Brindisa Soho",
            "lat":"51.513498226020616",
            "long":"-0.1365324711660761", 
          },
          {
            "location":"The Ivy",
            "lat":"51.51377681084826",
            "long":"-0.13570547813939632", 
          },
          {
            "location":"Social Eating House",
            "lat":"51.51384633329409",
            "long":"-0.13669454336771536", 
          },
          {
            "location":"Sketch",
            "lat":"51.51272101242594",
            "long":"-0.14154460633730118", 
          },
          
        ]
      },
      {
        "name":'Gyms',
        "theme": 'blue',
        "locations": [
          {
            "location":"Soul Cycle",
            "lat":"51.51487453441674",
            "long":"-0.13754049553756426", 
          },
          {
            "location":"Third Space",
            "lat":"51.5112913826169",
            "long":"-0.13584561289503203", 
          },
          {
            "location":"Health Club at The Dilly",
            "lat":"51.50928983266677",
            "long":"-0.13652202192888993", 
          },
          {
            "location":"Gymbox",
            "lat":"51.5105804960677",
            "long":"-0.12679944064786414", 
          },
          { 
            "location":"E by Equinox",
            "lat":"51.50613023329731",
            "long":"-0.13868141972135395", 
          },
          {
            "location":"The RAC",
            "lat":"51.50607444013857",
            "long":"-0.1349785663741968",
          }
        ]
      },
      
    ]
  },
  {
    "map":"commerce",
    "centre": {lat: 51.510538170250584, lng: -0.13475638367586099},
    "categories": [
      {
        "name":'Technology',
        "theme": 'green',
        "locations": [
          {
            "location":"Twitter",
            "lat":"51.5105473080987",
            "long":"-0.13644606182880414"
          },
          {
            "location":"Facebook",
            "lat":"51.5168085804867",
            "long":"-0.13407890109972231", 
          },
          {
            "location":"King.Com",
            "lat":"51.51550165931433",
            "long":"-0.13548419207401013", 
          },
          {
            "location":"Snapchat",
            "lat":"51.51208254986088",
            "long":"-0.13542493759518165", 
          },
          {
            "location":"Palantir",
            "lat":"51.51578188848366",
            "long":"-0.1315880323235861", 
          },
          
        ]
      },
      {
        "name":'Finance',
        "theme": 'orange',
        "locations": [
          {
            "location":"Generation Investment Management",
            "lat":"51.51057829020093",
            "long":"-0.13647259116654195", 
          },
          {
            "location":"Accel Partners",
            "lat":"51.512419318703664",
            "long":"-0.14029430064552276",
          },
          {
            "location":"Soros Fund Management",
            "lat":"51.51234515309219",
            "long":"-0.1402085509855293",
          },
          {
            "location":"SEGRO",
            "lat":"51.512346317188616",
            "long":"-0.14038437772538945",
          },
          {
            "location":"Davidson Kempner",
            "lat":"51.51205878344176",
            "long":"-0.1413037911283038",
          },
          {
            "location":"Ares Management",
            "lat":"51.5118397270804",
            "long":"-0.14012897923631307", 
          },
          {
            "location":"BlueCove",
            "lat":"51.511903165491866",
            "long":"-0.13998150429737521", 
          },
          {
            "location":"Tudor Capital",
            "lat":"51.51183056220261",
            "long":"-0.14000138827604697",
          },
          {
            "location":"Cerberus Capital",
            "lat":"51.51060547308952",
            "long":"-0.1396029370403382", 
          },
          {
            "location":"The Carlyle Group",
            "lat":"51.508850974335225",
            "long":"-0.13327113223901088", 
          },
          {
            "location":"Waverton Investment Management",
            "lat":"51.50837269059496",
            "long":"-0.13493477179255453", 
          },
          {
            "location":"Rokos Capital Management",
            "lat":"51.5118754626725",
            "long":"-0.14116978077029715", 
          },
          
        ]
      },
      {
        "name":'Other',
        "theme": 'red',
        "locations": [
          {
            "location":"Hikma Pharmaceutical",
            "lat":"51.51238547194943",
            "long":"-0.1402357912932626"
          },
          {
            "location":"Diageo",
            "lat":"51.51161838041888",
            "long":"-0.13643841273864035", 
          },
          {
            "location":"Formula 1",
            "lat":"51.50925043246762",
            "long":"-0.13320495871021412", 
          },
          {
            "location":"Hearst Magazines",
            "lat":"51.50997218739708",
            "long":"-0.13097094432287376", 
          },
          {
            "location":"The NFL",
            "lat":"51.509978957186604",
            "long":"-0.1308139781504789", 
          },           
        ]
      }
    ]
  }
];


export const MAP_THEME = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
];