"use strict";

////////////////////
// Module Imports //
//////////////////// 

import "nodelist-foreach-polyfill";
import { createDelegatedEventListener } from "@wearegood/good-utilities";
import * as CONSTANTS from "Modules/ShowHide/constants";
import ShowHide from "Modules/ShowHide/ShowHide";

////////////////////// 
// Module Constants //
//////////////////////

////////////////////////////////
// Module Classes & Functions //
////////////////////////////////

export default class ShowHideGroup {
  constructor(elem) {
    this.shGroup = elem;
    this.shComponentElements = this.shGroup.querySelectorAll(CONSTANTS.SEL_COMPONENT); 
    this.shComponentElements.forEach(element => {
      let singleShowHider = new ShowHide(element);
    });

    this.bindEventListeners();

    const FIRST_SH_COMP = this.shComponentElements.item(0);

    // Open first in group
    const OPEN_EVENT = new Event(CONSTANTS.OPEN_EVENT, {"bubbles":false, "cancelable":false});
    FIRST_SH_COMP.dispatchEvent(OPEN_EVENT);
    
  }

  handleToggleGroupEvent(event) {
    this.shComponentElements.forEach(element => {
      if(event.srcElement != element) {
        const CLOSE_EVENT = new Event(CONSTANTS.CLOSE_EVENT, {"bubbles":false, "cancelable":false});
        element.dispatchEvent(CLOSE_EVENT);
      } else {
        const OPEN_EVENT = new Event(CONSTANTS.OPEN_EVENT, {"bubbles":true, "cancelable":false});
        event.target.dispatchEvent(OPEN_EVENT);
      }      
    });
  }

  /**
   * bindCustomMessageEvents - Description
   *
   * @returns {type} Description
   */
  bindEventListeners() {   
    // this.action.removeEventListener('click', this.handleToggleEvent.bind(this));
    // this.action.addEventListener('click', this.handleToggleEvent.bind(this));
    this.shGroup.addEventListener("toggleGroup", this.handleToggleGroupEvent.bind(this));
  }
}