import Carousel from "Modules/Carousel/Carousel";
import * as CONSTANTS from "Modules/Carousel/constants";
import { getOuterHeight } from "@wearegood/good-utilities";

/**
 * Scroller - Description
 * @extends Carousel
 */
export default class Slider extends Carousel {
  /**
   *Creates an instance of Slider.
   * @param {*} element
   * @memberof Slider
   */
  constructor(element) {
    super(element);

    this.slideContainer.style.transitionDuration = this.transition + 'ms';
  }

  /**
   *
   *
   * @param {*} index
   * @memberof Slider
   */
  advanceCarousel(index) {
    this.setLayout();

    const INDEX_OF_TARGET_SLIDE = index;
    if (this.inTransition === false) {

      this.inTransition = true;
      this.slideContainer.style.transform = "translateX(" + (-100 * (this.slideWidth / this.slidesContainerWidth)) * INDEX_OF_TARGET_SLIDE + "%)";

      if (this.flexHeight) {
        let slideHeight = getOuterHeight(this.slides.item(INDEX_OF_TARGET_SLIDE))
        this.updateHeight(slideHeight);
      }

      this.currentSlide = this.slides.item(INDEX_OF_TARGET_SLIDE);
      this.setIndexToValue(INDEX_OF_TARGET_SLIDE);

      //console.log(this.carouselScrollbar);

      window.setTimeout(() => {
        this.inTransition = false;
      }, this.transition);
    }
  }
}
