// Tab Components module
"use strict";

/////////////
// imports //
/////////////

import PubSub from "pubsub-js";
//import smoothscroll from 'smoothscroll-polyfill';
import "nodelist-foreach-polyfill";
import { createCustomEvent, getClosestParent, messages as MESSAGES } from "@wearegood/good-utilities";

///////////////
// Constants //
///////////////

const SEL_TAB_PANEL = "[data-tabs-panel]";
const SEL_TAB_CONTROLS = "[data-tabs=tabs]";
const SEL_TAB_CONTROL = "[data-tabs-link]";
const SEL_TAB_CONTROL_SECTION = "[data-tabs-link-section]";

const CLASS_CURRENT = "is_Current";

// TODO: Add functionality to dynamically create tab controls
// const tabControlsContainerTemplate = `
//   <div class="cp_TabControls" data-tabs="controls">
//     <a class="tabAdvance--prev tabAdvance" href="#" data-tab-advance="p">Prev</a>
//     <a class="tabAdvance--next tabAdvance" href="#" data-tab-advance="n">Next</a>
//     <div class="tabs"></div>
//   </div>`;
// const tabControlTemplate = `<a href="#" class="tabLink" data-tabs="control"></a>`;

/////////////////////////
// Classes & Functions //
/////////////////////////

//smoothscroll.polyfill();

export default class TabbedContent {
  /**
   *Creates an instance of TabbedContent.
   * @param {*} element
   * @memberof TabbedContent
   */
  constructor(element) {
    this.component = element;
    this.componentType = this.component.dataset.tabsType || "single";
    this.componentScroll = this.component.dataset.tabsScrollType || "default";
    this.componentMode = this.component.dataset.tabsMode || "default";
    this.componentContent = this.component.dataset.tabsContent || "default";
    //this.config = JSON.parse(this.component.dataset.tabsConfig) || {};
    this.tabControlsContainer = this.component.querySelector(SEL_TAB_CONTROLS);
    this.tabControls = null;
    this.tabPanels = this.component.querySelectorAll(SEL_TAB_PANEL);
    this.currentIndex = 0;
    this.currentTab;

    this.bindCustomMessageEvents();
    this.subscribeToEvents();
    //this.setupTabs();

    if(this.componentType === 'multi') { 
      // if this is a multi-level component, select first section...
      //let firstSection = this.component.querySelector('[data-tabs=tab]');
      //this.switchSection(firstSection,false);
    } else {
      // If this is a single-level component, select the first tab and open..
      // window.console.log('open first tab');
      const firstTab = this.component.querySelector('[data-tabs-link]');
      this.switchTab(firstTab,false);
      this.component.classList.remove('dd_Open');
    }
  }

  /**
   * Set initial display of tabs.
   *
   * @memberof TabbedContent
   */
  setupTabs() {
    // Get the tab controls once they've been created

    
    this.tabPanels.item(this.currentIndex).classList.add(CLASS_CURRENT);
    this.tabControls = this.component.querySelectorAll(SEL_TAB_CONTROL);
    if(this.tabControls.length > 0){
      this.tabControls.item(this.currentIndex).classList.add(CLASS_CURRENT);
    }
  }

  /**
   * Update the current tab based on user interaction
   *
   * @param {*} e
   * @memberof TabbedContent
   */
  updateCurrentTab(index) {

    let targetIndex = index;

    // If the target index is not equal to the current index (i.e. the clicked tab isn't the current one )
    // update the tabbed content and scroll back to its start.
    if (targetIndex !== this.currentIndex) {

      if(this.tabPanels.item(this.currentIndex)) {
        this.tabPanels.item(this.currentIndex).classList.remove(CLASS_CURRENT);
        this.tabControls.item(this.currentIndex).classList.remove(CLASS_CURRENT);
      }

      if (this.tabPanels.item(targetIndex)) {
        this.tabPanels.item(targetIndex).classList.add(CLASS_CURRENT);
        this.tabControls.item(targetIndex).classList.add(CLASS_CURRENT);
      }

      this.currentIndex = targetIndex;
    }
  }

  switchTab(link, reposition, animate) {
    let tabLink = link;
    let targetID = tabLink.dataset.tabsLink;
    //let parentTab = getClosestParent(tabLink, '[data-tabs-tab]');
    let targetPanelSelector = `[data-tabs-panel="${targetID}"]`;
    
    // window.console.log(targetPanelSelector);

    if(!tabLink.classList.contains('is_Current')) {
      this.component.querySelectorAll('[data-tabs-link]').forEach(tab => {
        tab.classList.remove('is_Current');
      });
      
      tabLink.classList.add('is_Current');
    } else {
      // window.console.log('do nothing');
    }

    // if(this.componentMode = "dropdown") {
    //   this.component.classList.toggle('dd_Open');
    // }

    this.component.querySelectorAll('[data-tabs-panel]').forEach(panel => {
      panel.classList.remove('is_Current');
    });

    this.component.querySelector(targetPanelSelector).classList.add('is_Current');

    if(this.componentContent === "maps") {
      PubSub.publish('map-displayed');
    }
  }

  // switchSection(section, resposition) {
  //   let sectionContainer = section;

  //   window.console.log(sectionContainer);

  //   if(sectionContainer.classList.contains('is_Current')) {
  //     // Don't do anything. Just keep the open section open
  //   } else {
  //     if(this.component.querySelector('.cp_MSA__sectionTabs--main.is_Current')) {
  //       this.component.querySelector('.cp_MSA__sectionTabs--main.is_Current').classList.remove('is_Current');
  //     }

  //     sectionContainer.classList.add('is_Current');

  //     let firstSubSectionControl = sectionContainer.querySelector('[data-tabs-link');

  //     this.switchTab(firstSubSectionControl, resposition);
  //   }
  // }

  /**
   *
   *
   * @param {*} e
   * @memberof TabbedContent
   */
  handleTabSelectionEvent(e) {
    e.preventDefault();
    
    let targetLink; 

    if(!e.target.hasAttribute('data-tabs-link')) {
      targetLink = getClosestParent(e.target,SEL_TAB_CONTROL);
    } else {
      targetLink = e.target;
    }

    this.switchTab(targetLink, true);
    // Get data attribute and show panel that has matching ID
    

    // Get "tab" node if the click target is not already this
    // if(("tabs" in e.target.dataset) && e.target.dataset.tabs === "tab") {
    //   targetIndex = getIndexOfNode(e.target);
    // } else {
    //   let tabNode = e.target.closest(SEL_TAB_TAB);
    //   targetIndex = getIndexOfNode(tabNode);
    // }

    // this.updateCurrentTab(targetIndex);
  }

  handleTabSectionSelectionEvent(e) {
    e.preventDefault();

    let targetParent;

    if(!e.target.hasAttribute('data-tabs-link-section')) {
      targetParent = getClosestParent(e.target,SEL_TAB_CONTROL_SECTION).parentNode;
    } else {
      targetParent = e.target.parentNode;
    }

    //this.switchSection(targetParent, true);
  }

  handleShowHiddenTabsEvent(e) {
    e.preventDefault();
    this.component.classList.add('showAllTabs');
  }
  

  /**
   * Subscribes the component to global messages and sets the component's responses via internal custom events
   *
   * @memberof TabbedContent
   */
  subscribeToEvents() {
    PubSub.subscribe(MESSAGES.resize, () => {
      this.component.dispatchEvent(createCustomEvent("updateLayout"));
    });
  }

  /**
   * Bind custom messages for this class
   *
   * @memberof TabbedContent
   */
  bindCustomMessageEvents() {
    this.component.addEventListener("selectTab", this.handleTabSelectionEvent.bind(this));
    this.component.addEventListener("selectTabSection", this.handleTabSectionSelectionEvent.bind(this));
    this.component.addEventListener("showHiddenTabs", this.handleShowHiddenTabsEvent.bind(this));
    //this.component.addEventListener("updateLayout", this.updateLayout.bind(this));
  }
}
