// Show/Hide Components module
"use strict";

/////////////
// Imports //
/////////////

import "nodelist-foreach-polyfill";
import PubSub from "pubsub-js";
import { collapseElement, expandElement, messages as MESSAGES } from "@wearegood/good-utilities";
import * as CONSTANTS from "Modules/ShowHide/constants";

///////////////
// Constants //
///////////////

/////////////////////////
// Classes & Functions //
/////////////////////////

/**
 * ShowHide - Class representing a Show/Hide DOM component
 */
export default class ShowHide {
  /**
   * constructor - Description
   *
   * @param {object} element DOM element
   *
   * @returns {type} Description
   */
  constructor(element) {
    // Set properties
    this.component = element;
    this.action = this.component.querySelector(CONSTANTS.SEL_ACTION);
    this.content = this.component.querySelector(CONSTANTS.SEL_CONTENT);
    this.config = JSON.parse(this.component.getAttribute("data-showhide-config")) || {};
    this.animate = this.config.animate || true;
    this.startState = this.config.open || false;
    this.mode = this.config.mode || "js";
    this.expanded = false;
    this.isInGroup = this.config.group || false;

    this.bindEventListeners();
    this.setStartState();
  }

  openComponent() {
    if (this.mode === 'js') {
      expandElement(this.content);
    }

    this.component.classList.remove(CONSTANTS.CLASS_HIDE);
    this.component.classList.add(CONSTANTS.CLASS_DISPLAY);
    this.content.setAttribute('aria-hidden', 'false');
    this.expanded = true;
  }

  closeComponent() {
    if (this.mode === 'js') {
      collapseElement(this.content);
    }
    
    this.component.classList.remove(CONSTANTS.CLASS_DISPLAY);
    this.component.classList.add(CONSTANTS.CLASS_HIDE);
    this.content.setAttribute('aria-hidden', 'true');
    this.expanded = false;
  }

  /**
   * setStartState - Description
   *
   * @returns {type} Description
   */
  setStartState() {
    // Add inline CSS for transition time
    if (this.animate) {
      this.component.classList.add(CONSTANTS.CLASS_ANIMATE);
    }

    if (this.startState === true) {
      //expandElement(this.content);
      this.component.classList.add(CONSTANTS.CLASS_DISPLAY);
      this.content.setAttribute('aria-hidden', 'false');
      this.expanded = true;
    } else {
      this.component.classList.add(CONSTANTS.CLASS_HIDE);
      this.content.setAttribute('aria-hidden', 'true');
      this.expanded = false;
    }
  }

  /**
   * toggleControl - Description
   *
   * @param {type} event Description
   */
  handleToggleEvent(event) {
    event.stopPropagation();
    event.preventDefault();

    if(!this.isInGroup) {
      if (!this.expanded) {
        this.openComponent();
      } else {
        this.closeComponent();
      }
    } else {
      // Group Behaviour
      if (!this.expanded) {
        const CLOSE_GROUP_EVENT = new Event('toggleGroup', {"bubbles":true, "cancelable":false});
        this.component.dispatchEvent(CLOSE_GROUP_EVENT);

        PubSub.publish('groupOpen',this.config.name);
      } else {
        // If open, do nothing
        window.console.log('already open...');
      }
    }
  }

  handleOpenEvent(event) {
    event.preventDefault();
    event.stopPropagation();

    this.openComponent();
  }

  handleCloseEvent(event) {
    event.preventDefault();
    event.stopPropagation();

    this.closeComponent(); 
  }

  /**
   * bindCustomMessageEvents - Description
   *
   * @returns {type} Description
   */
  bindEventListeners() {   
    // this.action.removeEventListener('click', this.handleToggleEvent.bind(this));
    // this.action.addEventListener('click', this.handleToggleEvent.bind(this));
    this.component.addEventListener(CONSTANTS.ACTION_EVENT, this.handleToggleEvent.bind(this));
    this.component.addEventListener(CONSTANTS.OPEN_EVENT, this.handleOpenEvent.bind(this));
    this.component.addEventListener(CONSTANTS.CLOSE_EVENT, this.handleCloseEvent.bind(this));
  }
}

