// Show/Hide Constants

// Shared Selectors
export const SEL_COMPONENT = "[data-showhide=component]";
export const CLASS_DISPLAY = "is_Open";
export const CLASS_HIDE = "is_Closed";
export const CLASS_ANIMATE ="is_Animated";

export const SEL_GROUP_COMPONENT = "[data-showhide-group=component]";

// Action Selectors
export const SEL_ACTION = "[data-showhide=component] [data-showhide=toggle]";
export const SEL_CONTENT = "[data-showhide=content]";
export const ACTION_EVENT = "toggleShowHide";
export const OPEN_EVENT = "openShowHide";
export const CLOSE_EVENT = "closeShowHide";