import Carousel from "Modules/Carousel/Carousel"; 

/**
 * Fader - Description
 * @extends Carousel
 */
export default class Fader extends Carousel {
  constructor(element) {
    super(element);

    this.slides.forEach(slide => {
      slide.style.transitionDuration = this.transition + 'ms';
    });

    this.currentSlide.classList.add('is_Current');
    this.setCycle();
  }

  /**
   *
   *
   * @param {*} index
   * @memberof Slider
   */
  advanceCarousel(index) {
    //this.setLayout();
    const INDEX_OF_TARGET_SLIDE = index; 

    this.nextSlide = this.slides.item(INDEX_OF_TARGET_SLIDE);
    this.nextSlide.classList.add('is_Next');
    this.nextSlide.classList.add('is_Current');

    let transitionTimeout = setTimeout(() => {
      this.currentSlide.classList.remove('is_Current');
      this.nextSlide.classList.remove('is_Next');
      this.currentSlide = this.nextSlide;
    }, this.transition);    

    // Set class on target slide to sit it over current slide
  }
}