// Navigation Manager 

////////////////////
// Module Imports //
////////////////////

import "nodelist-foreach-polyfill";
import { createDelegatedEventListener, createGlobalMessenger } from "@wearegood/good-utilities";
import MainNavToggle from "Modules/Navigation/MainNavToggle";
import InPageNavigation from "Modules/Navigation/InPageNavigation";
import BannerAction from "Modules/Navigation/BannerAction";

//////////////////////
// Module Constants //
//////////////////////

const SEL_MAIN_NAV_TOGGLE = "[data-main-nav=toggle]";
const SEL_INPAGE_NAV = '[data-inpage-nav=menu]';
const SEL_TOP_LINK_GLOBAL = "[data-inpage-link=top]";
const SEL_BANNER_ACTION = "[data-banner-action=link]";

////////////////////////////////
// Module Classes & Functions //
////////////////////////////////

function initialiseMainNavigation() {
  const NAV_TOGGLE = document.querySelectorAll(SEL_MAIN_NAV_TOGGLE);

  Array.prototype.forEach.call(NAV_TOGGLE, element => {
    const newMainNavToggle = new MainNavToggle(element);
  });
} 

/**
 *
 *
 */
function initialiseInPageNavigation() {
  const INPAGE_MENUS = document.querySelectorAll(SEL_INPAGE_NAV);

  INPAGE_MENUS.forEach(element => {
    const newInPageNavigation = new InPageNavigation(element);
  });

  createGlobalMessenger("click", SEL_TOP_LINK_GLOBAL, "backToTop", true);
}

function initialiseBannerAction() {
  const BANNER_ACTIONS = document.querySelectorAll(SEL_BANNER_ACTION);

  BANNER_ACTIONS.forEach(element => {
    const newInPageNavigation = new BannerAction(element);
  });

}

/**
 * delegateEvents - Create delegated event listeners for the components managed within this module
 *
 * @returns {type} Description
 */
function delegateEvents() {
  createDelegatedEventListener("click", SEL_MAIN_NAV_TOGGLE, "toggleMainNav");
  createDelegatedEventListener("click", SEL_BANNER_ACTION, "bannerAction");
}


/**
 * initModule - Initialise this module and the components contained in it
 *
 * @returns {type} Description
 */
export default function initialiseNavigation() {
  // Create delegated event listeners for the components within this module
  delegateEvents();
  initialiseMainNavigation();
  initialiseInPageNavigation();
  initialiseBannerAction();
}