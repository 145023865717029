

/////////////
// Imports //
/////////////

import "nodelist-foreach-polyfill";

import { createNodeFromHTML } from "@wearegood/good-utilities";

///////////////
// Constants //
///////////////

const SEL_EMBED_PLAYER_HOLDER = "[data-embed-player=holder]"
const SEL_EMBED_PLAYER_LINK = "[data-embed-player=link]";
const CLASS_PLAYER_LOADED = "is_Loaded";

const SEL_PAGE_HEADER = ".cp_Brand";

/////////////////////////
// Classes & Functions //
/////////////////////////

export default class EmbeddedVideoPlayer {
  /**
   *Creates an instance of EmbeddedVideoPlayer.
   * @param {*} element
   * @memberof EmbeddedVideoPlayer
   */
  constructor(element) {
    this.embeddedVideo = element;
    this.embeddedVideoHolder = this.embeddedVideo.querySelector(SEL_EMBED_PLAYER_HOLDER);
    this.embeddedVideoLink = this.embeddedVideo.querySelector(SEL_EMBED_PLAYER_LINK);
    this.videoURL = this.embeddedVideoLink.getAttribute('href');
    this.loaded = false;

    this.bindCustomMessageEvents();
  }

  /**
   *
   *
   * @param {*} e
   * @memberof EmbeddedVideoPlayer
   */
  loadPlayer(e) {
    e.preventDefault();

    if(!this.loaded) {
      const IFRAME_TEMPLATE = `<iframe class="cp_VideoPlayer__iframe" src="${ this.videoURL }" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
      const IFRAME_NODE = createNodeFromHTML(IFRAME_TEMPLATE).item(0);

      this.embeddedVideoHolder.appendChild(IFRAME_NODE);
      this.embeddedVideo.classList.add(CLASS_PLAYER_LOADED); 

      this.loaded = true;
    } 
    
    this.repositionToVideo();
  }

  repositionToVideo() {
    let topPos = 0;

      topPos = this.embeddedVideoHolder.getBoundingClientRect().top;

      const VIEWPORT_SCROLL_POSITION = (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0);
      const PAGE_HEADER = document.querySelector(SEL_PAGE_HEADER);
      let pageHeaderHeight = parseFloat(getComputedStyle(PAGE_HEADER, null).height.replace("px", ""));

      if(pageHeaderHeight > 65) {
        pageHeaderHeight = 65;
      }

      topPos = topPos + VIEWPORT_SCROLL_POSITION - pageHeaderHeight;
    

    // Use the native JS scrolling functionality to smooth scroll to the correct position
    window.scrollTo({
      top: topPos,
      left: 0,
      behavior: 'smooth'
    });
  }

  /**
   *
   *
   * @memberof EmbeddedVideoPlayer
   */
  bindCustomMessageEvents() {
    this.embeddedVideo.addEventListener("loadEmbeddedVideo", this.loadPlayer.bind(this));
  }

  /**
   *
   *
   * @memberof EmbeddedVideoPlayer
   */
  subscribeToEvents() {

  }
}