"use strict";

import { ready, bindGlobalResizeMessage } from "@wearegood/good-utilities";

import ShowHideAPI from "Modules/Showhide";
import NavigationAPI from "Modules/Navigation";
import TabsAPI from "Modules/Tabs";
import CarouselAPI from "Modules/Carousel";
import LoaderAPI from "Modules/Loader";
import LayoutAPI from "Modules/Layout/Layout";
import MapsAPI from "Modules/Maps";
import ModalAPI from "Modules/Modal";
import VideoPlayerAPI from "Modules/EmbeddedVideoPlayer";
 
/**
 * initialiseComponentModules - call module init functions
 *
 * @returns {type} Description
 */ 
function initialiseComponentModules() {
  NavigationAPI.initialiseNavigation();
  TabsAPI.initialiseTabs();
  ShowHideAPI.initShowHideModule();
  CarouselAPI.initialiseCarousels();
  LayoutAPI.initModule();
  VideoPlayerAPI.initModule();
  ModalAPI.initialiseModals();

  bindGlobalResizeMessage();
  LoaderAPI.initLoader();
}

ready(initialiseComponentModules);

window.mapAPILoaded = function () {
  MapsAPI.initialiseMaps();
}
